import React from "react"
import ResetPassword from "~sections/Account/ResetPassword"
import { PageWrapper } from "~components/Core"
import { isLoggedIn } from "../../../src/services/auth"
import { navigate } from "gatsby"

const header = {
  headerClasses: "d-none",
  containerFluid: false,
}

export default function Profile() {
  if (!isLoggedIn()) {
   /* navigate("/")*/
  }
  return isLoggedIn() ? (
    <PageWrapper headerConfig={header}>
      <div>this profile</div>
    </PageWrapper>
  ) : (
    <></>
  )
}
